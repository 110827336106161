import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="f-content">
        <span>Neetu kumari</span>
        <span>neetu.bca3@gmail.com</span>
      </div>
    </div>
  );
};
export default Footer;
