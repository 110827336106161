import React from "react";
import "./Work.css";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "../../imgs/img1.jpg";
import img2 from "../../imgs/img2.png";
import img3 from "../../imgs/vector3.jpg";
import img4 from "../../imgs/img4.jpg";
import "swiper/css";

const Work = () => {
  return (
    <div className="portfolio" id='Work'>
      {/* heading */}
      
      <span>Recent Projects</span>
      <span>Portfolio</span>

      {/* Slider */}

      <Swiper spaceBetween={30}
              slidesPerView={3}
              grabCursor={true}
              className='portfolio-slider'
              >

        <SwiperSlide>
          <img src={img1} alt="" />
        </SwiperSlide>

        <SwiperSlide>
          <img src={img2} alt="" />
        </SwiperSlide>

        <SwiperSlide>
          <img src={img3} alt="" />
        </SwiperSlide>

        <SwiperSlide>
          <img src={img4} alt="" />
        </SwiperSlide>

      </Swiper>
    </div>
  );
};
export default Work;
