import React from "react";
import './App.css';
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import Footer from "./components/footer/Footer";
import Header from "./components/Header";
import Intro from "./components/intro/Intro";

import Services from "./components/services/Services";
import Work from "./components/work/Work";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Intro/>
      <Services/>
      <Experience/>
      <Work/>
      <Contact/>
      <Footer/>
        
    </div>
  );
};
export default App;
