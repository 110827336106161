import React from "react";
import "./Experience.css";

// import { Swiper, SwiperSlide } from "swiper/react";
 //import img1 from "../../imgs/img1.jpg";
// import img2 from "../../imgs/img2.png";
// import img3 from "../../imgs/vector3.jpg";
// import img4 from "../../imgs/img4.jpg";
// import "swiper/css";


const Experience = () => {
  return (
    <div className="experience" id="experience">
    
  
      <div className="achievement">
        <div className="circle" style={{color:'white'}}>2</div>
        <span>Years </span>
        <span>Experience</span>
      </div>
      <div className="achievement">
        <div className="circle" style={{color:'white'}}>10+</div>
        <span>Completed </span>
        <span>Projects</span>
      </div>
      <div className="achievement">
        <div className="circle" style={{color:'white'}}>1</div>
        <span>Company </span>
        <span>work</span>
      </div>

    </div>

    
  );
};
export default Experience;
